import {storyblokEditable} from '@storyblok/react'
import classNames from 'classnames'
import Image from 'next/image'
import React from 'react'
import {HeroImageSectionStoryblok} from 'types/storyblok-types'

export const ImageHeroSectionBlock = ({
  image_url,
  image_alt,
  bottom_rounded_overlay,
}: {
  blok?: HeroImageSectionStoryblok
  image_url: HeroImageSectionStoryblok['hero_image']['filename']
  image_alt?: HeroImageSectionStoryblok['hero_image']['alt']
  bottom_rounded_overlay?: HeroImageSectionStoryblok['bottom_rounded_overlay']
}) => {
  return (
    <>
      <Image
        src={image_url}
        className="object-cover"
        fill
        alt={image_alt ? image_alt : ''}
      />
      {bottom_rounded_overlay ? (
        <div className="absolute bottom-0 left-0 -z-10 h-12 w-full overflow-hidden rounded-t-dats-xl bg-white md:left-1/2 md:h-11 md:-translate-x-1/2 md:rounded-t-[50px]"></div>
      ) : null}
    </>
  )
}
const ImageHeroSectionContainer = ({
  blok,
  sectionAfterRoundedHero,
}: {
  blok: HeroImageSectionStoryblok
  sectionAfterRoundedHero: boolean
}) => {
  const sectionAfterRoundedHeroClass = classNames({
    'rounded-dats-xl md:rounded-t-[50px] -mt-11': sectionAfterRoundedHero,
  })
  return (
    <section
      className={`relative h-80 px-4 ${
        blok.bottom_rounded_overlay ? '-mb-11 pb-28 pt-16' : 'py-16'
      }  lg:px-[100px] ${sectionAfterRoundedHeroClass}`}
      {...storyblokEditable(blok)}
    >
      {blok.hero_image ? (
        <ImageHeroSectionBlock
          blok={blok}
          image_url={blok.hero_image?.filename}
          image_alt={blok.hero_image?.alt ?? ''}
          bottom_rounded_overlay={blok.bottom_rounded_overlay}
        />
      ) : null}
    </section>
  )
}

export default ImageHeroSectionContainer
